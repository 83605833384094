.shadow-custom {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

.bg-custom {
    /* background-color: #c7d5c2; Light color for the background */
    border-radius: 10px;
    /* Smooth rounded corners */
    padding: 20px;
    /* Add some padding for better spacing */
    color: black !important;
    /* Ensure text is visible on the background */
    border: 2px solid transparent;
    /* Default border */
}

.instructor-inner h6,
.instructor-inner h4,
.instructor-inner p {
    margin: 0;
}


.hover-border:hover .bg-custom {
    border-color: #00839b !important;
    /* Border color on hover */
    cursor: pointer;
    border: "1px solid #00839b"
}