@media (max-width: 700px) {
  .text-container p {
    font-size: 20px;

  }

  .content-section-img {
    height: 300px !important;
    width: 300px !important;
  }

  .choose-service-img {
    height: 300px !important;
    width: 300px !important;
  }
  .slider-img {
    height: 400px !important;
  }
}

.course-box-five {
  /* background-image: url('../../assets/img/home/services-card-1.jpg'); */
  background-size: cover;
  height: 250px !important;
}

.product-five {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right,
      rgba(219, 168, 55, 0.8),
      rgba(255, 217, 107, 0.5),
      rgba(219, 168, 55, 0.8));
}

.product-five-title {
  font-size: 20px !important;
}

.slider-img {
  height: 550px ;
  width: 100% !important;
}

.mySwiper {
  width: 100%;
}

.slider-content {
  width: 100% !important;
}

.slider-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.605);
}

.swiper-slide {
  width: 100% !important;
}

.swiper-pagination {
  position: absolute;
  z-index: 20;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -50px !important;
}

.swiper-pagination-bullet {
  background-color: transparent !important;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
  z-index: 21;
  height: 20px;
  width: 20px;
  margin: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.swiper-pagination-bullet-active {
  background-color: #fff !important;
  transition: all 0.3s ease-in-out;

}

.swiper-button-prev,
.swiper-button-next {
  background-color: transparent;
  height: 35px !important;
  width: 35px !important;
  border-radius: 50%;
  border: 2px solid #fff;
  font-size: 10px !important;
  transition: all 0.3s ease-in-out;

}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #fff;
  transition: all 0.3s ease-in-out;

}

.swiper-button-prev:hover::after,
.swiper-button-next:hover::after {
  color: rgba(219, 168, 55, 0.8) !important;
  transition: all 0.3s ease-in-out;

}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 20px !important;
  color: #fff !important;
  transition: all 0.3s ease-in-out;

}


.text-navy {
  color: #0E0E0D !important;
}

.book-btn {
  font-size: 16px;
  display: inline-block;
  border: 1px solid #DBA837;
  font-weight: 700 !important;
  color: #ffffff !important;
  background: #DBA837;
  border-radius: 5px;
  text-align: center;
  padding: 12px 45px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-transform: initial;
  box-shadow: inset 0 0 0 0 #fff;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.book-btn:hover {
  background-color: #ffffff;
  border-color: #DBA837;
  color: #DBA837 !important;
  box-shadow: inset 0 50px 0 0 #ffffff;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.testimonial-slider .swiper-button-next {
  display: none;
}

.testimonial-slider .swiper-button-prev {
  display: none;
}

.testimonial-slider .swiper-pagination-bullet {
  background-color: transparent !important;
  border-radius: 50%;
  border: 2px solid #0E0E0D;
  position: relative;
  z-index: 21;
  height: 20px;
  width: 20px;
  margin: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.testimonial-slider .swiper-pagination-bullet-active {
  background-color: #000 !important;
  transition: all 0.3s ease-in-out;
}


.testimonial-slider .swiper-pagination {
  position: absolute;
  z-index: 20;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -40px !important;
}

.slider-testimonial-content {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin: 40px;
  margin-top: 10px !important;
}

.client-img {
  height: 60px !important;
}

.client-item {
  border: 1px dashed #999999;
}

.content-section-img {
  height: 500px;
  width: 500px;
  border: 1px solid #999999;
  border-radius: 50%;
}

.count {
  height: 50px;
  width: 50px;
  transition: all 0.3s ease-in-out;

}

.process-card:hover {
  border: 1px solid #DBA837 !important;
}

.process-card:hover .count {
  background-color: #DBA837 !important;
  transition: all 0.3s ease-in-out;
}

.choose-service-img {
  height: 500px;
  width: 500px;
}