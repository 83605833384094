.shadow-custom {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px; /* Optional: add rounded corners */
    background-color: #fff; /* Ensure background is white */
    border: 2px solid transparent; /* Default border */
    transition: border-color 0.3s ease; /* Smooth transition for border color */
  }
  .my-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .card-body {
    padding: 20px; /* Adjust padding for card content */
  }
  
  .col-item p {
    margin: 0; /* Remove default margins */
    line-height: 1.5; /* Adjust line height for readability */
  }
  
  .hover-border:hover {
    border-color: #00839b !important; /* Border color on hover */
    cursor: pointer;
  }
  
.card {
  background-color: #b2d9e1;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* max-width: 90%; */
  /* width: 400px; */
  margin-bottom: 10px;
  padding: 20px;
  /* box-sizing: border-box; */
  transition: all 0.3s ease-in-out;
  /* display: ruby-text; */
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-color: #00839b !important; /* Border color on hover */
  cursor: pointer;
}

.card h2 {
  margin-top: 0;
}

.card p {
  margin-bottom: 0;
}