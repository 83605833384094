.quote{
    width: 40px !important;
}
.customCard{
    height: fit-content !important;
}

.mission-vision {
    border: 0.5px solid #7B1FFE;
    width: fit-content;
    border-radius: 12px;
    padding: 8px;
}

.mission-vision img {
    max-width: 40px !important;
}

.get-certified p {
    text-align: justify;
}

.certified-group:hover {
    border: 0.5px solid #7B1FFE;

}

.text-violet {
    color: #7B1FFE;
}

.learn-anything p {
    text-align: justify;
}

.award-list span {
    width: 20px;
}